html,
body {
  padding: 0;
  margin: 0;
}

iframe { border: 0; }

.display-inline  { display: inline; }
.display-none    { display: none; }
.display-initial { display: initial; }

.w-full { width: 100%; }

.v-align-space-between {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}

.v-align-space-between > * {
  width: 100%;
}

@media (--medium-viewport) {
  .md\3A display-inline  { display: inline; }
  .md\3A display-none    { display: none; }
  .md\3A display-initial { display: initial; }
}

@media (--normal-viewport) {
  .nm\3A display-inline  { display: inline; }
  .nm\3A display-none    { display: none; }
  .nm\3A display-initial { display: initial; }
}

@media (--large-viewport) {
  .lg\3A display-inline  { display: inline; }
  .lg\3A display-none    { display: none; }
  .lg\3A display-initial { display: initial; }
}

@media (--extra-large-viewport) {
  .xl\3A display-inline  { display: inline; }
  .xl\3A display-none    { display: none; }
  .xl\3A display-initial { display: initial; }
}
