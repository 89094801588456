.article-list {
  padding: 0;
  list-style: none outside none;
}

.article-list-item {
  padding: 0;
  margin: 0;
  border-bottom: var(--hr);
}

.article-list-item:first-child {
  border-top: var(--hr);
}

.article-list-link {
  display: block;
  padding: var(--space-12) 0 var(--space-8);
}  

.article-list-meta {
  margin-bottom: 0.5em;
}
