.fs-xxs {
  font-size: var(--fs-xxs);
  line-height: var(--lh-xxs);
  letter-spacing: var(--ls-xxs);
}

.fs-xs {
  font-size: var(--fs-xs);
  line-height: var(--lh-xs);
  letter-spacing: var(--ls-xs);
}

.fs-s {
  font-size: var(--fs-s);
  line-height: var(--lh-s);
  letter-spacing: var(--ls-s);
}

.fs-sm {
  font-size: var(--fs-sm);
  line-height: var(--lh-sm);
  letter-spacing: var(--ls-sm);
}

.fs-m {
  font-size: var(--fs-m);
  line-height: var(--lh-m);
  letter-spacing: var(--ls-m);
}

.fs-l {
  font-size: var(--fs-l);
  line-height: var(--lh-l);
  letter-spacing: var(--ls-l);
}

.fs-xl {
  font-size: var(--fs-xl);
  line-height: var(--lh-xl);
  letter-spacing: var(--ls-xl);
}

.fs-xxl {
  font-size: var(--fs-xxl);
  line-height: var(--lh-xxl);
  letter-spacing: var(--ls-xxl);
}

.fs-xxxl {
  font-size: var(--fs-xxxl);
  line-height: var(--lh-xxxl);
  letter-spacing: var(--ls-xxxl);
}

@media (--medium-viewport) {
  .md\3A fs-xxs {
    font-size: var(--fs-xxs);
    line-height: var(--lh-xxs);
    letter-spacing: var(--ls-xxs);
  }

  .md\3A fs-xs {
    font-size: var(--fs-xs);
    line-height: var(--lh-xs);
    letter-spacing: var(--ls-xs);
  }

  .md\3A fs-s {
    font-size: var(--fs-s);
    line-height: var(--lh-s);
    letter-spacing: var(--ls-s);
  }

  .md\3A fs-s {
    font-size: var(--fs-s);
    line-height: var(--lh-s);
    letter-spacing: var(--ls-s);
  }

  .md\3A fs-sm {
    font-size: var(--fs-sm);
    line-height: var(--lh-sm);
    letter-spacing: var(--ls-sm);
  }

  .md\3A fs-m {
    font-size: var(--fs-m);
    line-height: var(--lh-m);
    letter-spacing: var(--ls-m);
  }

  .md\3A fs-l {
    font-size: var(--fs-l);
    line-height: var(--lh-l);
    letter-spacing: var(--ls-l);
  }

  .md\3A fs-xl {
    font-size: var(--fs-xl);
    line-height: var(--lh-xl);
    letter-spacing: var(--ls-xl);
  }

  .md\3A fs-xxl {
    font-size: var(--fs-xxl);
    line-height: var(--lh-xxl);
    letter-spacing: var(--ls-xxl);
  }

  .md\3A fs-xxxl {
    font-size: var(--fs-xxxl);
    line-height: var(--lh-xxxl);
    letter-spacing: var(--ls-xxxl);
  }
}

@media (--normal-viewport) {
  .nm\3A fs-xxs {
    font-size: var(--fs-xxs);
    line-height: var(--lh-xxs);
    letter-spacing: var(--ls-xxs);
  }

  .nm\3A fs-xs {
    font-size: var(--fs-xs);
    line-height: var(--lh-xs);
    letter-spacing: var(--ls-xs);
  }

  .nm\3A fs-sm {
    font-size: var(--fs-sm);
    line-height: var(--lh-sm);
    letter-spacing: var(--ls-sm);
  }

  .nm\3A fs-m {
    font-size: var(--fs-m);
    line-height: var(--lh-m);
    letter-spacing: var(--ls-m);
  }

  .nm\3A fs-l {
    font-size: var(--fs-l);
    line-height: var(--lh-l);
    letter-spacing: var(--ls-l);
  }

  .nm\3A fs-xl {
    font-size: var(--fs-xl);
    line-height: var(--lh-xl);
    letter-spacing: var(--ls-xl);
  }

  .nm\3A fs-xxl {
    font-size: var(--fs-xxl);
    line-height: var(--lh-xxl);
    letter-spacing: var(--ls-xxl);
  }

  .nm\3A fs-xxxl {
    font-size: var(--fs-xxxl);
    line-height: var(--lh-xxxl);
    letter-spacing: var(--ls-xxxl);
  }
}

@media (--large-viewport) {
  .lg\3A fs-xxs {
    font-size: var(--fs-xxs);
    line-height: var(--lh-xxs);
    letter-spacing: var(--ls-xxs);
  }

  .lg\3A fs-xs {
    font-size: var(--fs-xs);
    line-height: var(--lh-xs);
    letter-spacing: var(--ls-xs);
  }

  .lg\3A fs-s {
    font-size: var(--fs-s);
    line-height: var(--lh-s);
    letter-spacing: var(--ls-s);
  }

  .lg\3A fs-sm {
    font-size: var(--fs-sm);
    line-height: var(--lh-sm);
    letter-spacing: var(--ls-sm);
  }

  .lg\3A fs-m {
    font-size: var(--fs-m);
    line-height: var(--lh-m);
    letter-spacing: var(--ls-m);
  }

  .lg\3A fs-l {
    font-size: var(--fs-l);
    line-height: var(--lh-l);
    letter-spacing: var(--ls-l);
  }

  .lg\3A fs-xl {
    font-size: var(--fs-xl);
    line-height: var(--lh-xl);
    letter-spacing: var(--ls-xl);
  }

  .lg\3A fs-xxl {
    font-size: var(--fs-xxl);
    line-height: var(--lh-xxl);
    letter-spacing: var(--ls-xxl);
  }

  .lg\3A fs-xxxl {
    font-size: var(--fs-xxxl);
    line-height: var(--lh-xxxl);
    letter-spacing: var(--ls-xxxl);
  }
}

@media (--extra-large-viewport) {
  .xl\3A fs-xxs {
    font-size: var(--fs-xxs);
    line-height: var(--lh-xxs);
    letter-spacing: var(--ls-xxs);
  }

  .xl\3A fs-xs {
    font-size: var(--fs-xs);
    line-height: var(--lh-xs);
    letter-spacing: var(--ls-xs);
  }

  .xl\3A fs-s {
    font-size: var(--fs-s);
    line-height: var(--lh-s);
    letter-spacing: var(--ls-s);
  }

  .xl\3A fs-sm {
    font-size: var(--fs-sm);
    line-height: var(--lh-sm);
    letter-spacing: var(--ls-sm);
  }

  .xl\3A fs-m {
    font-size: var(--fs-m);
    line-height: var(--lh-m);
    letter-spacing: var(--ls-m);
  }

  .xl\3A fs-l {
    font-size: var(--fs-l);
    line-height: var(--lh-l);
    letter-spacing: var(--ls-l);
  }

  .xl\3A fs-xl {
    font-size: var(--fs-xl);
    line-height: var(--lh-xl);
    letter-spacing: var(--ls-xl);
  }

  .xl\3A fs-xxl {
    font-size: var(--fs-xxl);
    line-height: var(--lh-xxl);
    letter-spacing: var(--ls-xxl);
  }

  .xl\3A fs-xxxl {
    font-size: var(--fs-xxxl);
    line-height: var(--lh-xxxl);
    letter-spacing: var(--ls-xxxl);
  }
}
