figure {
  margin: 2em 0;
  max-width: 100%;
}

figure img {
  display: block;
  max-width: 100%;
}

figcaption {
  margin: 1em 0 2em;
}

.text-only figure {
  display: none !important;
}

.teaser-image-wrapper {
  background-color: var(--platinum);
}

.teaser-issue .teaser-image-wrapper {
  background-color: transparent;
}

.teaser-image {
  display: block;
  aspect-ratio: attr(width) / attr(height);
}

.issue-cover {
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}
