.pager {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  border-top: var(--hr);
  padding-top: 0.5em;
  opacity: 1;
  transition: opacity 0.5s;
}

.pager-link,
.pager-button {
  width: 100%;
  flex-basis: 100%;
}

.pager-next {
  text-align: right;
}

.pager-button {
  display: block;
  font-size: inherit;
  text-align: center;
  text-transform: initial;
  border: 0;
  color: inherit;
  background: inherit;
  cursor: pointer;
}

.pager-hidden {
  opacity: 0;
  transition: opacity 1s;
}

.pager-hidden .grid-pager-button {
  cursor: default;
}
