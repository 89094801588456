h1,
h2 {
  margin: var(--space-12) 0 0;
}

h3,
h4 {
  margin: var(--space-10) 0 0;
}

h5,
h6 {
  margin: var(--space-10) 0 0;
}

p:not([class]),
ol:not([class]),
ul:not([class]) {
  margin: 1em 0;
}

ul,
ol {
  list-style-position: outside;
  padding: 0;
  margin-left: 0;
}

ol:not([class]) li,
ul:not([class]) li {
  margin-left: 1em;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child,
p:first-child,
ul:first-child,
ol:first-child {
  margin-top: 0;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
p:last-child,
ul:last-child,
ol:last-child {
  margin-bottom: 0;
}

.t-lede {
  margin-bottom: 1em;
}

@media (--medium-viewport) {
  h1,
  h2 {
    margin: var(--space-36) 0 0;
  }
  
  h3,
  h4 {
    margin: var(--space-24) 0 0;
  }
  
  h5,
  h6 {
    margin: var(--space-12) 0 0;
  }
}
