.main-footer {
  overflow: auto;
  grid-column: 1 / -1;
}

.main-footer-info,
.main-footer-partners {
  margin-top: var(--row-gap);
}

.main-footer-info p {
  margin: 0.5em 0;
}

.main-footer-partners .main-footer-body {
  display: flex;
  margin: 0 calc(-1 * var(--column-gap));
  align-items: center;
}

.main-footer-partner-link {
  flex-shrink: 1;
  margin: 0 var(--column-gap);
  max-width: 25%;
  height: 50px;
}

.main-footer-partner-image {
  display: block;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

@media (--medium-viewport) {
  .main-footer-info,
  .main-footer-partners {
    display: flex;
    flex-wrap: nowrap;
    margin-top: var(--column-gap);
  }

  .main-footer-title {
    flex-grow: 0;
    width: 20%;
    margin: 0;
  }

  .main-footer-body {
    display: flex;
    flex-grow: 1;
    justify-content: stretch;
    margin: 0;
  }
  
  .main-footer-body p {
    margin: 0;
    flex-grow: 1;
  }

  .main-footer-info {
    align-items: flex-start;
  }

  .main-footer-partners {
    align-items: flex-end;
  }

  .main-footer-partner-link {
    flex-shrink: 0;
    flex-grow: 1;
  }
}

@media (--normal-viewport) {
  .main-footer-content {
    display: flex;
    align-items: flex-end;
    align-items: stretch;
  }

  .main-footer-info,
  .main-footer-partners {
    flex-wrap: wrap;
    flex-grow: 1;
    flex-shrink: 1;
    align-content: space-between;
    margin: 0;
  }

  .main-footer-partners {
    margin: 0 0 0 var(--column-gap);
    padding-bottom: 0.1em;
  }

  .main-footer-title {
    width: 100%;
  }

  .main-footer-info .main-footer-body,
  .main-footer-partners .main-footer-body {
    margin: var(--column-gap) 0 0 0;
  }

  .main-footer-partner-link {
    margin: 0;
  }
}
