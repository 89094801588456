.tingle-modal__close {
  top: var(--space-12);
  right: var(--space-12);
  width: 28px;
  height: 28px;
  margin: 0;
}

.tingle-modal__closeIcon svg {
  display: none;
}

.tingle-modal__closeIcon,
.tingle-modal__closeIcon:before,
.tingle-modal__closeIcon:after {
  position: absolute;
  top: calc(50% - 1px);
  left: 0;
  display: block;
  height: 2px;
  width: 100%;;
  background: transparent;
  content: '';
  cursor: pointer;
  border-radius: 1px;
}

.tingle-modal__closeIcon:before {
  top: 0;
  transform: rotate(45deg);
  background: var(--black);
}

.tingle-modal__closeIcon:after {
  top: 0;
  transform: rotate(-45deg);
  background: var(--black);
}
