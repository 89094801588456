* {
  border-color: inherit;
}

a:not([class]) { text-decoration-color: var(--silver); }

main,
.c-light {
  background-color: var(--white);
  color: var(--black);
  border-color: var(--grey);
}

.c-light a:not([class]) { text-decoration-color: var(--silver); }

.c-silver {
  background-color: var(--silver);
  color: var(--black);
  border-color: var(--grey);
}

.c-silver a:not([class]) { text-decoration-color: var(--grey); }

.c-silver * {
  border-color: var(--grey);
}

.c-platinum {
  background-color: var(--platinum);
  color: var(--black);
  border-color: var(--grey);
}

.c-platinum a:not([class]) { text-decoration-color: var(--grey); }

.c-platinum * {
  border-color: var(--grey);
}

.c-dark {
  background-color: var(--black);
  color: var(--white);
  border-color: var(--white);  
}

.c-dark a:not([class]) { text-decoration-color: var(--white); }

.c-dark * {
  border-color: var(--white);  
}

.c-red {
  background-color: var(--red);
  color: var(--black);
  border-color: var(--black);  
}

.c-red a:not([class]) { text-decoration-color: var(--black); }

.c-red * {
  border-color: var(--black);  
}

.c-issue {
  background-color: var(--c-issue);
}

.c-text-lighter {
  color: var(--grey);
}

.c-text-red {
  color: var(--red);
}

.c-dark .t-teaser a:not(.button),
.c-dark .fs-body a:not(.button) {
  background-image: url('data:image/svg+xml;utf8,<svg preserveAspectRatio="none" viewBox="0 0 1 1" xmlns="http://www.w3.org/2000/svg"><line x1="0" y1="0" x2="1" y2="1" style="stroke:%23eeeeee" /></svg>');
}

.button.selected,
.link-button.selected,
.no-touch .button:hover,
.no-touch .link-button:hover {
  color: var(--white);
  background-color: var(--black);
  border-color: var(--black);
  transition: background-color 0.4s, color 0.2s;
}

.c-silver .button.selected,
.c-silver .link-button.selected,
.no-touch .c-silver .button:hover,
.no-touch .c-silver .link-button:hover {
  color: var(--silver);
  background-color: var(--black);
  border-color: var(--black);
}

.c-dark .button.selected,
.c-dark .link-button.selected,
.no-touch .c-dark .button:hover,
.no-touch .c-dark .link-button:hover {
  border-color: var(--grey);
  color: var(--black);
  background-color: var(--grey);
}

.no-touch .link-arrow:hover {
  color: var(--c-issue);
}
