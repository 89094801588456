html,
body {
  height: 100%;
}

body {
  overflow-x: hidden;
}

.menu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-top: var(--top-bar-h);
  padding-right: 20%;
  background-color: var(--silver);
}

.menu-trigger {
  position: fixed;
  clip: rect(0, 0, 0, 0);
}

.menu-trigger-label {
  position: fixed;
  top: 0;
  left: calc(var(--space-v) - 1.2rem);
  z-index: 3;
  width: var(--top-bar-h);
  height: var(--top-bar-h);
  cursor: pointer;
}

.menu-trigger-label span, 
.menu-trigger-label span:before, 
.menu-trigger-label span:after {
  position: absolute;
  top: calc(var(--top-bar-h) / 2 - 1px);
  left: calc(var(--top-bar-h) / 4);
  display: block;
  height: 2px;
  width: calc(var(--top-bar-h) / 2);
  background: var(--c-top-bar);
  content: '';
  cursor: pointer;
  border-radius: 1px;
  transition: all 250ms ease-in-out;
}

.menu-trigger-label span {
  overflow: visible;
  text-indent: -9999px;
}

.menu-trigger-label span:before {
  top: calc(var(--top-bar-h) * -0.16);
  left: 0;
} 

.menu-trigger-label span:after {
  top: calc(var(--top-bar-h) * 0.16);
  left: 0;
}

.menu-trigger:checked + label span {
  background-color: transparent;
}

.menu-trigger:checked + label span:before {
  top: 0;
  transform: rotate(45deg);
}

.menu-trigger:checked + label span:after {
  top: 0;
  transform: rotate(-45deg);
}

.main-page {
  position: relative;
  top: 0;
  bottom: 100%;
  left: 0;
  z-index: 1;
  box-sizing: border-box;
  min-width: 100%;
  min-height: 100%;
  padding-top: calc(var(--top-bar-h) - 1px);
  transition: left 0.2s;
}

.menu-trigger:checked ~ .main-page {
  left: 80%;
  border-left: 1px solid var(--black);
}

@media (--medium-viewport) {
  .menu {
    padding-right: 50%;
  }

  .menu-trigger:checked ~ .main-page {
    left: 50%;
  }
}

@media (--large-viewport) {
  .menu {
    padding-right: 67%;
  }

  .menu-trigger:checked ~ .main-page {
    left: 33%;
  }
}
