/**
 * 1 rem = 10px
 */
html { font-size: 10px; }

/**
 * Custom properties
 */
:root {
  /* this variable is set with javascript */
  --vh: 1vh;

  /* Colours */
  --black:        #000000;
  --grey:         #868686;
  --silver:       #cccccc; /* was silver */
  --platinum:     #eeeeee; /* was platinum */
  --white:        #ffffff;
  --red:          #fc0007;

  /* Typefaces */
  --f-brut: 'Brut', Helvetica, Arial, sans-serif;
  --f-life: 'Life', "Times New Roman", Times, serif;
  
  /* Font sizes */
  --fs-xxs:  1.1rem; /* fs-1 */
  --fs-xs:   1.2rem; /* fs-2 */
  --fs-s:    1.4rem; /* fs-3 */
  --fs-sm:   1.5rem; /* new */
  --fs-m:    1.7rem; /* fs-4 */
  --fs-l:    2.3rem; /* fs-5 */
  --fs-xl:   3.0rem; /* fs-7 */
  --fs-xxl:  4.0rem; /* fs-8 */
  --fs-xxxl: 5.2rem; /* fs-9 */

  /* Line heights (corresponding) */
  --lh-xxs:  1.29;
  --lh-xs:   1.28;
  --lh-s:    1.27;
  --lh-sm:   1.26;
  --lh-m:    1.25;
  --lh-l:    1.22;
  --lh-xl:   1.15;
  --lh-xxl:  1.09;
  --lh-xxxl: 1;

  /* Letter-spacing (corresponding) */
  --ls-xxs:   normal;
  --ls-xs:    normal;
  --ls-s:     normal;
  --ls-sm:    normal;
  --ls-m:    -0.0095em;
  --ls-l:    -0.013em;
  --ls-xl:   -0.019em;
  --ls-xxl:  -0.024em;
  --ls-xxxl: -0.034em;

  /* Spacing */
  --space-3:  0.3rem;
  --space-6:  0.6rem;
  --space-8:  0.8rem;
  --space-10: 1.0rem;
  --space-12: 1.2rem;
  --space-18: 1.8rem;
  --space-24: 2.4rem;
  --space-36: 3.6rem;
  --space-64: 6.4rem;
  --space-96: 9.6rem;

  /* Default border */
  --hr: 1px solid var(--grey);

  /* Layout properties */
  --top-bar-h: 3.2rem;
  --space-h: var(--space-6);
  --space-v: var(--space-12);
  --column-gap: var(--space-10);
  --column-gap-l: var(--space-18);
  --row-gap: var(--column-gap);
  --row-gap-l: var(--space-18);
}

@media (--medium-viewport) {
  :root {
    --top-bar-h: 5rem;
    --space-h: var(--space-12);
    --space-v: var(--space-18);
    --column-gap-l: var(--space-24);
    --row-gap-l: var(--space-24);
  }
}

@media (--normal-viewport) {
  :root {
    --space-h: var(--space-24);
    --space-v: var(--space-24);
    --column-gap: var(--space-12);
    --column-gap-l: var(--space-36);
    --row-gap-l: var(--space-36);
    }
}

@media (--large-viewport) {
  :root {
    --space-h: var(--space-36);
    --space-v: var(--space-36);
  }
}
