.teaser-image-wrapper {
  margin: 0;
}

.teaser-image {
  display: block;
  width: 100%;
  height: auto;
}

.teaser-title-wrapper {
  display: block;
}

.teaser-title,
.teaser-subtitle,
.teaser-author {
  margin: 0;
}

.teaser-title-fixed {
  height: 3.75em;
  overflow: hidden;
}

.teaser-title-auto-height { height: auto; }

.teaser-cta-buttons {
  margin-top: 1em;
}

@media (--medium-viewport) {
  .md\3A teaser-title-fixed {
    height: 3.75em;
    overflow: hidden;
  }
  .md\3A teaser-title-auto-height { height: auto; }
}

@media (--normal-viewport) {
  .nm\3A teaser-title-fixed {
    height: 3.75em;
    overflow: hidden;
  }
  .nm\3A teaser-title-auto-height { height: auto; }
}

@media (--large-viewport) {
  .lg\3A teaser-title-fixed {
    height: 3.75em;
    overflow: hidden;
  }
  .lg\3A teaser-title-auto-height { height: auto; }
}

@media (--extra-large-viewport) {
  .xl\3A teaser-title-fixed {
    height: 3.75em;
    overflow: hidden;
  }
  .xl\3A teaser-title-auto-height { height: auto; }
}
