.inline-image-wrapper {
  position: relative;
  margin: 0 0 var(--row-gap);
}

.inline-image-wrapper-portrait {
  width: 50%;
}

.inline-image {
  width: 100%;
  max-width: 100%;
  height: auto;
}

.inline-image-caption {
  text-align: left;
}

.inline-image-link {
  display: none;
}

.inline-audio {
  width: 100%;
}

.inline-video {
  position: relative;
  width: 100%;
  padding: 0 0 56.25%;
  margin: 1em 0 4em;
  background: var(--black);
}

.inline-video iframe {
  position: absolute;
  width: 100%;
  height: 100%;
}

.inline-video figcaption {
  position: absolute;
  margin: 0;
  bottom: -3em;
}

@media (--medium-viewport) {
  .article-image-wrapper-portrait,
  .inline-image-wrapper-portrait {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (--normal-viewport) {
  .article-images {
    margin: 0;
  }

  .article-text,
  .article-images .inline-image-wrapper,
  .article-images .article-image-wrapper {
    position: relative;
  }

  .article-text .inline-image-wrapper {
    position: absolute;
    left: calc(100% + var(--column-gap-l));
    width: 50%;
  }

  .article-image-wrapper {
    margin-top: 0;
    margin-left: 0;
  }

  .article-text .inline-image-wrapper-portrait {
    width: 25%;
  }

  .article-images .article-image-link,
  .inline-image-link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
  }

  .inline-video iframe {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .inline-video figcaption {
    position: absolute;
    margin: 0;
    bottom: -3em;
  }
}


@media (--large-viewport) {
  .article-text .inline-image-wrapper {
    width: 100%;
  }

  .article-text .inline-image-wrapper-portrait {
    width: 50%;
  }
}

@media (--extra-large-viewport) {
  .article-text .inline-image-wrapper {
    width: 66.6667%;
  }

  .article-text .inline-image-wrapper-portrait {
    width: 33.3333%;
  }
}
