.menu-inner {
  padding: var(--space-24);
}

.menu-list {
  padding: 0;
  list-style: none outside none;
}

.menu-list:nth-child(n+2) {
  margin-top: var(--space-12);
}

.menu-list-item {
  margin: 0;
}

@media (--medium-viewport) {
  .menu-inner {
    --fs-menu: var(--fs-l);
    --lh-menu: var(--lh-l);
    --ls-menu: var(--ls-l);

    padding: var(--space-36);
  }  

  .menu-list:nth-child(n+2) {
    margin-top: var(--space-24);
  }
}

@media (--extra-large-viewport) {  
  .menu-inner {
    padding: var(--space-36);
  }  
}
