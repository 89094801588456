/* buttons & links styled as a button */
button,
.button,
.link-button {
  display: inline-block;
  box-sizing: border-box;
  padding: 0.6em 0.4em 0.25em;
  font-family: var(--f-brut);
  text-transform: uppercase;
  line-height: 1;
  border: var(--hr);
  text-decoration: none;
  color: inherit;
  background: inherit;
  cursor: pointer;
}

button:not([class*="fs-"]),
.button:not([class*="fs-"]),
.link-button:not([class*="fs-"]) {
  font-size: 0.9em;
}

.no-touch .link-button:hover {
  cursor: pointer;
}

.link-button-wide {
  width: 100%;
  text-align: center;
}
