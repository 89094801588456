h1,
h2,
h3 {
  font-size:      var(--fs-l);
  line-height:    var(--lh-l);
  letter-spacing: var(--ls-l);
}

h4 {
  font-size:      var(--fs-m);
  line-height:    var(--lh-m);
  letter-spacing: var(--ls-m);
}

h5,
h6 {
  font-size:      var(--fs-s);
  line-height:    var(--lh-s);
  letter-spacing: var(--ls-s);
}

p,
ul,
ol {
  font-size: inherit;
}

blockquote {
  font-size:      var(--fs-m);
  line-height:    var(--lh-m);
  letter-spacing: var(--ls-m);
}

figcaption {
  font-size:      var(--fs-xs);
  line-height:    var(--lh-xs);
  letter-spacing: var(--ls-xs);
}

@media (--medium-viewport) {
  h1 {
    font-size:      var(--fs-xxxl);
    line-height:    var(--lh-xxxl);
    letter-spacing: var(--ls-xxxl);
  }

  h2 {
    font-size:      var(--fs-xxl);
    line-height:    var(--lh-xxl);
    letter-spacing: var(--ls-xxl);
  }

  h3 {
    font-size:      var(--fs-l);
    line-height:    var(--lh-l);
    letter-spacing: var(--ls-l);
  }
  
  h5,
  h6 {
    font-size:      var(--fs-m);
    line-height:    var(--lh-m);
    letter-spacing: var(--ls-m);
  }

  blockquote {
    font-size:      var(--fs-l);
    line-height:    var(--lh-l);
    letter-spacing: var(--ls-l);
  }
}

@media (--extra-large-viewport) {
  h3 {
    font-size:      var(--fs-xl);
    line-height:    var(--lh-xl);
    letter-spacing: var(--ls-xl);
  }
  
  h4,
  h5,
  h6 {
    font-size:      var(--fs-l);
    line-height:    var(--lh-l);
    letter-spacing: var(--ls-l);
  }

  figcaption {
    font-size:      var(--fs-s);
    line-height:    var(--lh-s);
    letter-spacing: var(--ls-s);
  }
}
