/*
.tingle-modal--overflow,
.tingle-modal-box__content {
  padding: 0;
}
*/
.tingle-modal {
  background: rgba(255, 255, 255, 0.95);
}

@supports (backdrop-filter: blur(20px)) {
  .tingle-modal {
    background: rgba(255, 255, 255, 0.85);
  }  
}

.tingle-modal-box {
  width: 100%;
  height: 99.8%; /* Tingle thinks it overflows if 100% */
  background: transparent;
}

.tingle-modal-box__content {
  width: 100%;
  height: 100%;
  padding: 0 var(--space-12);
}
