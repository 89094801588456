*[class*="grid"] { 
  display: grid;
  grid-auto-flow: row dense;
}

/* prevent grid columns from expanding */
*[class*="grid"] > * {
  min-width: 0;
}

.grid-1c   { grid-template-columns: 1fr; }
.grid-2x1c { grid-template-columns: repeat(2, 1fr); }
.grid-1c2c { grid-template-columns: 1fr 2fr; }
.grid-1c3c { grid-template-columns: 1fr 3fr; }
.grid-2c1c { grid-template-columns: 2fr 1fr; }
.grid-2c3c { grid-template-columns: 2fr 3fr; }
.grid-3x1c { grid-template-columns: repeat(3, 1fr); }
.grid-4x1c { grid-template-columns: repeat(4, 1fr); }
.grid-5x1c { grid-template-columns: repeat(5, 1fr); }
.grid-4c1c { grid-template-columns: 4fr 1fr; }

/* shortcut: we only use reversed for 2-column grids */
.grid-order-reversed > *:first-child { grid-column: 2; }
.grid-order-regular  > *:first-child { grid-column: 1; }

.grid-span-2 { grid-column-end: span 2; }
.grid-span-3 { grid-column-end: span 3; }

@media (--medium-viewport) {
  .md\3A grid-1c   { grid-template-columns: 1fr; }
  .md\3A grid-2x1c { grid-template-columns: repeat(2, 1fr); }
  .md\3A grid-1c2c { grid-template-columns: 1fr 2fr; }
  .md\3A grid-1c3c { grid-template-columns: 1fr 3fr; }
  .md\3A grid-2c1c { grid-template-columns: 2fr 1fr; }
  .md\3A grid-2c3c { grid-template-columns: 2fr 3fr; }
  .md\3A grid-3x1c { grid-template-columns: repeat(3, 1fr); }
  .md\3A grid-4x1c { grid-template-columns: repeat(4, 1fr); }
  .md\3A grid-5x1c { grid-template-columns: repeat(5, 1fr); }
  .md\3A grid-4c1c { grid-template-columns: 4fr 1fr; }

  .md\3A grid-order-reversed > *:first-child { grid-column: 2; }
  .md\3A grid-order-regular  > *:first-child { grid-column: 1; }

  .md\3A grid-span-2 { grid-column-end: span 2; }
  .md\3A grid-span-3 { grid-column-end: span 3; }
}

@media (--normal-viewport) {
  .nm\3A grid-1c   { grid-template-columns: 1fr; }
  .nm\3A grid-2x1c { grid-template-columns: repeat(2, 1fr); }
  .nm\3A grid-1c2c { grid-template-columns: 1fr 2fr; }
  .nm\3A grid-1c3c { grid-template-columns: 1fr 3fr; }
  .nm\3A grid-2c1c { grid-template-columns: 2fr 1fr; }
  .nm\3A grid-2c3c { grid-template-columns: 2fr 3fr; }
  .nm\3A grid-3x1c { grid-template-columns: repeat(3, 1fr); }
  .nm\3A grid-4x1c { grid-template-columns: repeat(4, 1fr); }
  .nm\3A grid-5x1c { grid-template-columns: repeat(5, 1fr); }
  .nm\3A grid-4c1c { grid-template-columns: 4fr 1fr; }

  .nm\3A grid-order-reversed > *:first-child { grid-column: 2; }
  .nm\3A grid-order-regular  > *:first-child { grid-column: 1; }

  .nm\3A grid-span-2 { grid-column-end: span 2; }
  .nm\3A grid-span-3 { grid-column-end: span 3; }
}

@media (--large-viewport) {
  .lg\3A grid-1c   { grid-template-columns: 1fr; }
  .lg\3A grid-2x1c { grid-template-columns: repeat(2, 1fr); }
  .lg\3A grid-1c2c { grid-template-columns: 1fr 2fr; }
  .lg\3A grid-1c3c { grid-template-columns: 1fr 3fr; }
  .lg\3A grid-2c1c { grid-template-columns: 2fr 1fr; }
  .lg\3A grid-2c3c { grid-template-columns: 2fr 3fr; }
  .lg\3A grid-3x1c { grid-template-columns: repeat(3, 1fr); }
  .lg\3A grid-4x1c { grid-template-columns: repeat(4, 1fr); }
  .lg\3A grid-5x1c { grid-template-columns: repeat(5, 1fr); }
  .lg\3A grid-4c1c { grid-template-columns: 4fr 1fr; }

  .lg\3A grid-order-reversed > *:first-child { grid-column: 2; }
  .lg\3A grid-order-regular  > *:first-child { grid-column: 1; }

  .lg\3A grid-span-2 { grid-column-end: span 2; }
  .lg\3A grid-span-3 { grid-column-end: span 3; }
}

@media (--extra-large-viewport) {
  .xl\3A grid-1c     { grid-template-columns: 1fr; }
  .xl\3A grid-2x1c   { grid-template-columns: repeat(2, 1fr); }
  .xl\3A grid-1c2c   { grid-template-columns: 1fr 2fr; }
  .xl\3A grid-1c3c   { grid-template-columns: 1fr 3fr; }
  .xl\3A grid-2c1c   { grid-template-columns: 2fr 1fr; }
  .xl\3A grid-2c3c   { grid-template-columns: 2fr 3fr; }
  .xl\3A grid-3x1c   { grid-template-columns: repeat(3, 1fr); }
  .xl\3A grid-2c1c1c { grid-template-columns: 2fr 1fr 1fr; }
  .xl\3A grid-4x1c   { grid-template-columns: repeat(4, 1fr); }
  .xl\3A grid-5x1c   { grid-template-columns: repeat(5, 1fr); }
  .xl\3A grid-4c1c   { grid-template-columns: 4fr 1fr; }

  .xl\3A grid-order-reversed > *:first-child { grid-column: 2; }
  .xl\3A grid-order-regular  > *:first-child { grid-column: 1; }

  .xl\3A grid-span-2 { grid-column-end: span 2; }
  .xl\3A grid-span-3 { grid-column-end: span 3; }
}
