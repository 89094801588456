.gallery-carousel {
  width: 100%;
  height: 100%;
}

.gallery-carousel .flickity-viewport,
.gallery-carousel .flickity-slider {
  height: 100% !important;
}

.carousel-slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  box-sizing: border-box;
  padding: var(--space-36) var(--space-36) var(--space-24) var(--space-36);
  width: 100%;
  height: 100%;
  margin: 0;
}

.carousel-slide-container {
  flex-grow: 1;
  position: relative;
  width: 100%;
}

.carousel-image-wrapper {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.carousel-image {
  display: block;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  margin: 0 auto;
}

.carousel-image-wrapper::after {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  content: " ";
  display: block;
  width: 50px;
  height: 50px;
  margin: -25px 0 0 -25px;
  border-radius: 50%;
  border: 4px solid var(--black);
  border-color: var(--black) transparent var(--black) transparent;
  animation: loader 1.2s linear infinite;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.carousel-caption {
  width: 100%;
  padding: var(--space-12) 0 0 0;
  margin: 0;
  text-align: center;
}

@media (min-aspect-ratio: 3/2) {
  .carousel-image-wrapper {
    align-items: flex-end;
  }
}
