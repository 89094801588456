/* LIFE */
@font-face {
  font-family: "Life";
  src:  url(../fonts/LifeLTStd-Roman-subset.woff2) format("woff2"), 
        url(../fonts/LifeLTStd-Roman-subset.zopfli.woff) format("woff");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Life";
  src:  url(../fonts/LifeLTStd-Italic-subset.woff2) format("woff2"), 
        url(../fonts/LifeLTStd-Italic-subset.zopfli.woff) format("woff");
  font-style: italic;
  font-weight: normal;
}

@font-face {
  font-family: "Life";
  src:  url(../fonts/LifeLTStd-Bold-subset.woff2) format("woff2"), 
        url(../fonts/LifeLTStd-Bold-subset.zopfli.woff) format("woff");
  font-style: normal;
  font-weight: 700;
}

/* Brut Grotesque */
@font-face {
  font-family: "Brut";
  src:  url(../fonts/Brut_Grotesque_WEB-Regular-subset.woff2) format("woff2"), 
        url(../fonts/Brut_Grotesque_WEB-Regular-subset.zopfli.woff) format("woff");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Brut";
  src:  url(../fonts/Brut_Grotesque_WEB-Italic-subset.woff2) format("woff2"), 
        url(../fonts/Brut_Grotesque_WEB-Italic-subset.zopfli.woff) format("woff");
  font-style: italic;
  font-weight: normal;
}
