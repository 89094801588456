.teaser-list {
  padding: 0;
  margin: 0;
  list-style: none outside none;
}

.teaser-list-item {
  border-bottom: var(--hr);
  padding: 0.7em 0 0.4em;
}

.teaser-list-link {
  text-decoration: none;
  color: inherit;
}

.no-touch .teaser-list-link:hover * {
  color: var(--grey);
  transition: color 0.5s;
}
