.article-mono > h3,
.article-mono > h4,
.article-mono > h5,
.article-mono > h6,
.article-mono > p,
.article-mono > ul,
.article-mono > ol,
.article-mono > div {
  max-width: 70rem;
  margin-right: auto;
  margin-left: auto;
}

.article-mono-header,
.article-mono > .t-lede {
  max-width: 100rem;
  margin-right: auto;
  margin-left: auto;
}

.article-mono .inline-image-wrapper,
.article-mono .video {
  position: relative;
  left: 0;
  max-width: 120rem;
  margin: 2em auto 4em;
}

.article-mono .inline-image-wrapper-portrait {
  max-width: 60rem;
}

.article-mono .inline-image-wrapper-half {
  width: 50%;
}

.article-mono .inline-image-wrapper-quarter {
  width: 25%;
}

.article-mono .inline-image-wrapper-portrait .inline-image {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 80vh;
  margin-right: auto;
  margin-left: auto;
}

.article-mono .inline-image-caption {
  text-align: center;
}

.article-mono .inline-video {
  position: relative;
  width: 100%;
  padding: 0 0 56.25%;
  background: var(--black);
}

.article-mono .inline-video iframe {
  position: absolute;
  width: 100%;
  height: 100%;
}

.article-mono .inline-video figcaption {
  position: absolute;
  margin: 0;
  bottom: -3em;
}


.article-mono .inline-image {
  display: block;
  width: 100%;
  height: auto;
}
