.no-cookie-consent .cookie-consent {
  display: block;
}

.cookie-consent {
  display: none;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 999;
  box-sizing: border-box;
  width: 100%;
  max-width: 400px;
  padding: var(--space-12);
}

.cookie-consent-message {
  padding: var(--space-24);
  font-size: var(--fs-s);
  line-height: var(--lh-s);
}

.cookie-consent p {
  margin: 0.5em 0;
}

.cookie-consent-policy-link {
  text-decoration: underline;
}

.cookie-consent-button-wrapper {
  text-align: center;
}

.cookie-consent-button {
  margin: 1em 0 0;
}
