.teaser-highlight-inner {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: stretch;
  align-items: stretch;
  width: 100%;
  height: 100%;
}

.teaser-highlight-image-wrapper {
  width: 100%;
  flex-grow: 999;
  background-position: center;
  background-size: cover;
}

.teaser-highlight-header {
  position: relative;
  width: 100%;
  align-self: flex-end;
}

.teaser-highlight-title {
  margin: 0.5em 0;
}

/* Overlay */

.teaser-highlight-overlay {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: stretch;
  align-items: stretch;
}

.teaser-highlight-overlay .teaser-highlight-image-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  background-attachment: fixed;
}

.teaser-highlight-viewport {
  position: relative;
  display: block;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh) * 100 - var(--top-bar-h));
}

.teaser-highlight-overlay .teaser-highlight-header {
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
}

.teaser-highlight-overlay header {
  margin-bottom: 0;
}

.teaser-highlight-overlay .teaser-highlight-body {
  z-index: 999;
  display: none;
  max-width: 1000px;
  margin-top: var(--grid-column-gap);
}

.teaser-highlight-overlay * {
  text-shadow: 0 0 10px rgba(238,238,238,0.5);
}

.teaser-highlight-overlay-white * {
  color: var(--white);
  text-shadow: 0 0 10px rgba(0,0,0,0.5);
}

.teaser-highlight-overlay-black .meta-top {
  border-color: var(--black);
}

.teaser-highlight-overlay-white .meta-top {
  border-color: var(--white);
}

@media (orientation: portrait) {
  .teaser-highlight-viewport {
    height: 50vh;
  }

  .teaser-highlight-overlay .teaser-highlight-image-wrapper {
    background-attachment: initial;
  }
}

@media (--normal-viewport) {
  .teaser-highlight-overlay .teaser-highlight-body {
    display: block;
  }
}
