.search-bar-trigger {
	position: fixed;
	clip: rect(0, 0, 0, 0);
}

.search-bar-trigger-label {
	display: none;
  position: fixed;
  top: 0;
  right: calc(var(--space-v) - 1.2rem);
  z-index: 4;
  width: var(--top-bar-h);
  height: var(--top-bar-h);
	background-repeat: no-repeat;
	background-size: 40% 40%;
	background-position: 50% 60%;
	background-image: var(--top-bar-search-icon, url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:none;stroke:%23000;stroke-miterlimit:10;%7D%3C/style%3E%3C/defs%3E%3Ccircle class='cls-1' cx='12.75' cy='12.75' r='6.25'/%3E%3Cline class='cls-1' x1='24.5' y1='24.5' x2='17' y2='17'/%3E%3C/svg%3E"));
  cursor: pointer;
}

.search-bar-trigger-label span {
	display: block;
	width: 0;
	height: 0;
	clip: rect(0, 0, 0, 0);
	overflow: hidden;
  text-indent: -9999px;
}

.search-bar {
	position: fixed;
	top: var(--top-bar-h);
	z-index: 3;
	box-sizing: border-box;
	overflow: hidden;
	width: 100%;
	max-height: 0;
	background: var(--white);
	transition: max-height 0.2s ease-in-out;
}

.search-bar-trigger:checked ~ .search-bar {
	max-height: 10rem;
	border-bottom: 1px solid var(--black);
}

@media (--medium-viewport) {
	.search-bar-trigger-label {
		display: block;
	}
}
