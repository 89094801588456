:root {
  --ad-width: 150px;
}

.announcement-wrapper {
  overflow: visible;
  padding: var(--row-gap) 0;
  border-top: var(--hr);
}

.announcement-slot-wrapper.empty {
  display: none;
}

.announcement-list,
.announcement-slot-list {
  display: grid;
  align-items: start;
  grid-column-gap: var(--column-gap);
  grid-row-gap: var(--column-gap);
  grid-template-columns: repeat(2, 1fr);  
  padding: 0;
  margin: var(--column-gap);
  list-style: none outside none;
}

.announcement-list .announcement-list {
  display: block;
  margin: 0;
}

.announcement-list.collapsed {
  position: relative;
  width: 100%;
  height: calc(var(--ad-width) / 2);
}

.announcement-slot-list {
  margin: 0;
}

.announcement-slot-list.with-3-items {
  grid-template-columns: 50% calc(50% - var(--column-gap));
}

.announcement-item {
  margin: 0;
}

.announcement-slot-list.with-3-items .announcement-item:first-child {
  grid-row: 1 / span 2;
}

.announcement-list.collapsed .announcement-item {
  position: absolute;
}

.announcement-slot-list .announcement-item {
  margin: 0;
}

.announcement-link {
  display: block;
  text-decoration: none;
}

.announcement-image {
  display: block;
  width: 100%;
  height: auto;
}

@media (--medium-viewport) {
  .announcements-wrapper {
    display: grid;
    grid-template-columns: 1fr calc(var(--ad-width) + 2 * var(--space-v));
    border-top: var(--hr);
  }

  .announcements {
    padding: var(--space-v);
    border-left: var(--hr);
  }

  .announcement-wrapper {
    padding: 0;
    border: 0;
  }

  .announcement-list {
    display: block;
    margin: 0;
  }

  .announcement-list.collapsed {
    width: var(--ad-width);
    height: calc(var(--ad-width) / 2 );
  } 

  .announcement-list.collapsed {
    position: relative;
    width: var(--ad-width);
    height: calc(var(--ad-width) / 2 );
  }

  .announcement-item {
    margin-bottom: var(--space-v);
  }
}

@media (--large-viewport) {
  :root {
    --ad-width: 175px;
  }

  .announcements-wrapper {
    grid-template-columns: 1fr calc(var(--ad-width) + 2 * var(--space-v));
  }
}
