hr {
  border: 0;
  border-bottom: var(--hr);
}

[class*=" border-"] {
  border-color: inherit;
}

.border-none { border-width: 0; }
.border-top {
  border-top-style: solid;
  border-top-width: 1px; 
}
.border-right {
  border-right-style: solid;
  border-right-width: 1px; 
}
.border-bottom {
  border-bottom-style: solid;
  border-bottom-width: 1px; 
}
.border-left {
  border-left-style: solid;
  border-left-width: 1px; 
}

.border-stretch { box-sizing: border-box; }
.border-stretch.border-top,
.border-stretch.border-bottom { width: 100%; }
.border-stretch.border-right,
.border-stretch.border-left { height: 100%; }

@media (--medium-viewport) {
  .md\3A border-none { border-width: 0; }
  .md\3A border-top {
    border-top-style: solid;
    border-top-width: 1px; 
  }
  .md\3A border-right {
    border-right-style: solid;
    border-right-width: 1px; 
  }
  .md\3A border-bottom {
    border-bottom-style: solid;
    border-bottom-width: 1px; 
  }
  .md\3A border-left {
    border-left-style: solid;
    border-left-width: 1px; 
  }

  .md\3A border-stretch { box-sizing: border-box; }
  .md\3A border-stretch.border-top,
  .md\3A border-stretch.border-bottom { width: 100%; }
  .md\3A border-stretch.border-right,
  .md\3A border-stretch.border-left { height: 100%; }
}

@media (--normal-viewport) {
  .nm\3A border-none { border-width: 0; }
  .nm\3A border-top {
    border-top-style: solid;
    border-top-width: 1px; 
  }
  .nm\3A border-right {
    border-right-style: solid;
    border-right-width: 1px; 
  }
  .nm\3A border-bottom {
    border-bottom-style: solid;
    border-bottom-width: 1px; 
  }
  .nm\3A border-left {
    border-left-style: solid;
    border-left-width: 1px; 
  }

  .nm\3A border-stretch { box-sizing: border-box; }
  .nm\3A border-stretch.border-top,
  .nm\3A border-stretch.border-bottom { width: 100%; }
  .nm\3A border-stretch.border-right,
  .nm\3A border-stretch.border-left { height: 100%; }
}

@media (--large-viewport) {
  .lg\3A border-none { border-width: 0; }
  .lg\3A border-top {
    border-top-style: solid;
    border-top-width: 1px; 
  }
  .lg\3A border-right {
    border-right-style: solid;
    border-right-width: 1px; 
  }
  .lg\3A border-bottom {
    border-bottom-style: solid;
    border-bottom-width: 1px; 
  }
  .lg\3A border-left {
    border-left-style: solid;
    border-left-width: 1px; 
  }

  .lg\3A border-stretch { box-sizing: border-box; }
  .lg\3A border-stretch.border-top,
  .lg\3A border-stretch.border-bottom { width: 100%; }
  .lg\3A border-stretch.border-right,
  .lg\3A border-stretch.border-left { height: 100%; }
}

@media (--extra-large-viewport) {
  .xl\3A border-none { border-width: 0; }
  .xl\3A border-top {
    border-top-style: solid;
    border-top-width: 1px; 
  }
  .xl\3A border-right {
    border-right-style: solid;
    border-right-width: 1px; 
  }
  .xl\3A border-bottom {
    border-bottom-style: solid;
    border-bottom-width: 1px; 
  }
  .xl\3A border-left {
    border-left-style: solid;
    border-left-width: 1px; 
  }

  .xl\3A border-stretch { box-sizing: border-box; }
  .xl\3A border-stretch.border-top,
  .xl\3A border-stretch.border-bottom { width: 100%; }
  .xl\3A border-stretch.border-right,
  .xl\3A border-stretch.border-left { height: 100%; }
}
