.max-article {
  max-width: 1750px;
}

.article-excerpt::after {
  position: absolute;
  bottom: 0;
  z-index: 999;
  display: block;
  content: " ";
  width: 100%;
  height: 5em;
  background-image: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1));
}

.article-body h4 {
  text-indent: 4em;
}

.article-body h4 + p {
  margin-top: 0;
}

@media (--normal-viewport) {
  .article-mono > .t-lede,
  .article-embedded-images > .t-lede {
    margin-bottom: var(--space-64);
  }
  
  .article-embedded-images > .t-lede {
    width: calc(150% + var(--column-gap-l));
  }

  .article-embedded-images > .inline-image-wrapper:first-child + p {
    margin-top: 0;
  }
}

@media (--large-viewport) {
  .article-mono > .t-lede,
  .article-embedded-images > .t-lede {
    margin-bottom: var(--space-64);
  }
  .article-embedded-images > .t-lede {
    width: calc(200% + var(--column-gap-l));
  }
}

@media (--extra-large-viewport) {
  .article-embedded-images > .t-lede {
    width: calc(0.8333 * (200% + var(--row-gap) - var(--column-gap)));
  }
}
