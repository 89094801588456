a {
  color: inherit;
  text-decoration: none;
}

/* ordinary link styling */
a:not([class]) {
  color: inherit;
  text-decoration: underline;
}

/* links followed by an arrow */
.link-arrow::after {
  position: relative;
  top: 0.1ex;
  /* right arrow character */
  content: ' \2192';
}

.uc.link-arrow::after,
.uc .link-arrow::after {
  position: static;
}
