.form-label-above {
  display: block;
  margin: var(--space-12) 0 0;
}

.form-input-textfield {
  box-sizing: border-box;
  width: 100%;
  padding: var(--space-10);
  margin: var(--space-8) 0 var(--space-24);
  font-size: inherit;
  line-height: 1;
  background-color: transparent;
  border: var(--hr);
}

.form-input-checkbox {
  position: absolute;
  left: -99999px;
}

.form-checkbox {
  margin: var(--space-8) 0 var(--space-24);
}

.form-checkbox label {
  position: relative;
  display: block;
  padding-left: 2em;
}

.form-checkbox label::after {
  position: absolute;
  display: block;
  content: " ";
  top: -0.125em;
  left: 0;
  width: 0.75em;
  height: 0.75em;
  cursor: pointer;
  border: var(--hr);
  text-align: center;
  font-size: 1.5em;
  line-height: 1em;
}

.form-checkbox [type="checkbox"]:checked + label::after {
  content: "\2713\0020";
}

.form-button {
	height: 1.25em;
	box-sizing: content-box;
	display: inline-block;
	padding: .7em .4em .25em;
	line-height: 1.25em;
	font-size: inherit;
  text-transform: uppercase;
	border: var(--hr);
	background: none;
}

.form-trap {
  position: absolute !important;
  height: 1px; 
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
}

.account-error,
.account-login-form,
.account-password-form,
.account-reset-form {
  max-width: 500px;
}

.subscribe-form {
  box-sizing: border-box;
  width: 100%;
  height: auto;
  min-height: 100vh;
  padding: 0;
  border: var(--hr);
}

.account-page {
  box-sizing: border-box;
  display: flex;
  height: calc(100 * var(--vh) - (var(--top-bar-h) - 1px));
  justify-content: center;
  align-items: center;
  align-content: space-around;
}

.account-page-login {
  box-sizing: border-box;
  width: 100%;
  max-width: 400px;
  padding: var(--space-12);
}

.account-error {
  margin: var(--space-18) 0;
  color: var(--red);
}

.account-error p {
  margin: 0;
}

.account-login-submit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.form-checkbox .hf-warning,
.form-textfield .hf-warning {
  color: var(--red);
}

.form-input-textfield ~ .hf-warning {
  position: relative;
  top: calc(-1 * var(--space-12));
}

.form-input-checkbox ~ .hf-warning {
  position: relative;
  top: var(--space-12);
}

.search-bar-form {
	display: flex;
	flex-wrap: nowrap;
	align-items: stretch;
}

.search-page-form-main {
	display: flex;
	flex-wrap: nowrap;
	align-items: stretch;
  margin-bottom: var(--space-24);
}

.search-page-form-type {
	display: inline-block;
	margin-right: 1em;
}

.search-bar-form-term,
.search-page-form-term {
	margin: 0 1rem 0 0;
}
