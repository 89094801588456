.issue-preview {
  display: flex;
  flex-wrap: wrap;
}

.issue-preview-header {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: baseline;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: -1em;
}


.issue-preview-text,
.issue-preview-carousel {
  box-sizing: border-box;
  width: 100%;
/*  border-top: var(--hr); */
}

.issue-preview-text {
/*  border-bottom: var(--hr); */
}

.issue-preview-text .link-button {
  width: 100%;
  text-align: center;
}

.issue-preview-carousel {
  height: 75vw;
  padding: 0 var(--column-gap);
/*  border-top: var(--hr);
  border-bottom: var(--hr); */
}

.issue-preview-carousel .carousel-container {
  height: 100%;
}

.issue-preview-carousel .carousel-slide {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-bottom: var(--space-36);
  margin: 0;
}

.issue-preview-carousel .carousel-image {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
}

.issue-preview-carousel .flickity-page-dots {
  height: var(--space-36);
}

.issue-page-cover {
  max-width: 40vw;
  max-height: 40vh;
  margin-top: var(--row-gap);
  margin-right: auto;
  margin-bottom: var(--row-gap);
  margin-left: 0;
}

.issue-page-cover-image {
  display: block;
  width: 100%;
  height: auto;
}

.issue-page-cta .link-button {
  width: 100%;
}

@media (--medium-viewport) {
  .issue-preview-carousel {
    height: 50vw;
  }

  .issue-preview-text .link-button {
    width: auto;
  }

  .issue-page-cover {
    margin-top: 0;
  }

  .grid-issue {
    display: grid;
    grid-gap: 0;
    grid-template-columns: 1fr 66%;
  }

  .issue-page-cover {
    max-width: 200px;
    max-height: unset;
    margin-right: var(--space-v);
  }
}

@media (--normal-viewport) {
  .issue-preview-text,
  .issue-preview-carousel {
    height: 400px;
  }

  .issue-preview-text {
    order: 2;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 35%;
/*    border-right: var(--hr); */
  }
  
  .issue-preview-carousel {
    flex-grow: 1;
    order: 3;
    width: auto;
  }

  .issue-preview-text .link-button {
    width: 100%;
  }

  .flickity-page-dots .dot {
    width: 6px;
    height: 6px;
    margin: 0 6px;
  }  

  .issue-page-cover {
    max-width: 250px;
  }
}

@media (--large-viewport) {
  .issue-preview-text,
  .issue-preview-carousel {
    height: 500px;
  }
}

@media (--extra-large-viewport) {
  .issue-page-cover {
    max-width: 300px;
  }
}
