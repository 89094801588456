/* highlight & single column teasers */
.no-touch .teaser-link:hover *,
.no-touch .teaser-list-link:hover * {
  color: var(--c-issue);
  border-color: var(--c-issue);
  transition: color 0.2s, border-color 0.2s;
}

.no-touch .teaser-link:hover .teaser-image-wrapper {
  background-color: var(--c-issue);
}

.no-touch .teaser-link:hover .teaser-image {
  mix-blend-mode: screen;
  filter: grayscale(100%);
}
