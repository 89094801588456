/* outer margins are uniform and responsive */
.padding-h {
  padding-right: var(--space-h);
  padding-left: var(--space-h);
}

.margin-h {
  margin-right: var(--space-h);
  margin-left: var(--space-h);
}

.margin-top-0 { margin-top: 0; }

/* 
 * vertical padding for the parent
 *
 * padding-v is essentially the same as: 
 * padding-v-12 md:padding-v-18 nm:padding-v-24 lg:padding-v-36
 */
.padding-v         { padding-top:    var(--space-v); 
                     padding-bottom: var(--space-v); }
.padding-v-12      { padding-top:    var(--space-12);
                     padding-bottom: var(--space-12); }
.padding-v-24      { padding-top:    var(--space-24);
                     padding-bottom: var(--space-24); }
.padding-v-36      { padding-top:    var(--space-36);
                     padding-bottom: var(--space-36); }
.padding-v-64      { padding-top:    var(--space-64);
                     padding-bottom: var(--space-64); }
.padding-v-0       { padding-top:    0;
                     padding-bottom: 0; }

.padding-top       { padding-top:    var(--space-v); }
.padding-top-12    { padding-top:    var(--space-12); }
.padding-top-24    { padding-top:    var(--space-24); }
.padding-top-36    { padding-top:    var(--space-36); }
.padding-top-64    { padding-top:    var(--space-64); }
.padding-top-0     { padding-top:    0; }

.padding-bottom    { padding-bottom: var(--space-v); }
.padding-bottom-12 { padding-bottom: var(--space-12); }
.padding-bottom-24 { padding-bottom: var(--space-24); }
.padding-bottom-36 { padding-bottom: var(--space-36); }
.padding-bottom-64 { padding-bottom: var(--space-64); }
.padding-bottom-0  { padding-bottom: 0; }


/* in-between margins for children */
* + .margin-top-8  { margin-top: var(--space-8); }
* + .margin-top-10 { margin-top: var(--space-10); }
* + .margin-top-12 { margin-top: var(--space-12); }
* + .margin-top-18 { margin-top: var(--space-18); }
* + .margin-top-24 { margin-top: var(--space-24); }
* + .margin-top-36 { margin-top: var(--space-36); }
* + .margin-top-64 { margin-top: var(--space-64); }

*[class*="grid"] {
  grid-column-gap: var(--column-gap);
  grid-row-gap: var(--row-gap);
}

.gap-l { 
  grid-column-gap:  var(--column-gap-l);
  grid-row-gap:     var(--row-gap-l);
}
.column-gap-l { grid-column-gap: var(--column-gap-l); }
.row-gap      { grid-row-gap:    var(--row-gap);      }
.row-gap-l    { grid-row-gap:    var(--row-gap-l);    }
.row-gap-0    { grid-row-gap: 0; }

/* no margins on grid children */
*[class*=" grid"] > * { margin: 0; }

@media (--medium-viewport) {
  .md\3A padding-h {
    padding-right: var(--space-v);
    padding-left: var(--space-v);
  }
  
  .md\3A margin-h {
    margin-right: var(--space-v);
    margin-left: var(--space-v);
  }

  .md\3A padding-v         { padding-top:    var(--space-v); 
                             padding-bottom: var(--space-v); }
  .md\3A padding-v-12      { padding-top:    var(--space-12);
                             padding-bottom: var(--space-12); }
  .md\3A padding-v-24      { padding-top:    var(--space-24);
                             padding-bottom: var(--space-24); }
  .md\3A padding-v-36      { padding-top:    var(--space-36);
                             padding-bottom: var(--space-36); }
  .md\3A padding-v-64      { padding-top:    var(--space-64);
                             padding-bottom: var(--space-64); }
  .md\3A padding-v-0       { padding-top:    0;
                             padding-bottom: 0; }

  .md\3A padding-top       { padding-top:    var(--space-v); }
  .md\3A padding-top-12    { padding-top:    var(--space-12); }
  .md\3A padding-top-24    { padding-top:    var(--space-24); }
  .md\3A padding-top-36    { padding-top:    var(--space-36); }
  .md\3A padding-top-64    { padding-top:    var(--space-64); }
  .md\3A padding-top-0     { padding-top:    0; }

  .md\3A padding-bottom    { padding-bottom: var(--space-v); }
  .md\3A padding-bottom-12 { padding-bottom: var(--space-12); }
  .md\3A padding-bottom-24 { padding-bottom: var(--space-24); }
  .md\3A padding-bottom-36 { padding-bottom: var(--space-36); }
  .md\3A padding-bottom-64 { padding-bottom: var(--space-64); }
  .md\3A padding-bottom-0  { padding-bottom: 0; }

  * + .md\3A margin-top-0  { margin-top: 0; }
  * + .md\3A margin-top-8  { margin-top: var(--space-8); }
  * + .md\3A margin-top-10 { margin-top: var(--space-10); }
  * + .md\3A margin-top-12 { margin-top: var(--space-12); }
  * + .md\3A margin-top-18 { margin-top: var(--space-18); }
  * + .md\3A margin-top-24 { margin-top: var(--space-24); }
  * + .md\3A margin-top-36 { margin-top: var(--space-36); }
  * + .md\3A margin-top-64 { margin-top: var(--space-64); }

  .md\3A column-gap-l   { grid-column-gap: var(--column-gap-l); }
  .md\3A row-gap        { grid-row-gap:    var(--row-gap);      }
  .md\3A row-gap-l      { grid-row-gap:    var(--row-gap-l);    }

  *[class*="md:grid"] > * { margin: 0; }
}

@media (--normal-viewport) {
  .nm\3A padding-h {
    padding-right: var(--space-v);
    padding-left: var(--space-v);
  }
  
  .nm\3A margin-h {
    margin-right: var(--space-v);
    margin-left: var(--space-v);
  }

  .nm\3A padding-v         { padding-top:    var(--space-v); 
                             padding-bottom: var(--space-v); }
  .nm\3A padding-v-12      { padding-top:    var(--space-12);
                             padding-bottom: var(--space-12); }
  .nm\3A padding-v-24      { padding-top:    var(--space-24);
                             padding-bottom: var(--space-24); }
  .nm\3A padding-v-36      { padding-top:    var(--space-36);
                             padding-bottom: var(--space-36); }
  .nm\3A padding-v-64      { padding-top:    var(--space-64);
                             padding-bottom: var(--space-64); }
  .nm\3A padding-v-0       { padding-top:    0;
                             padding-bottom: 0; }

  .nm\3A padding-top       { padding-top:    var(--space-v); }
  .nm\3A padding-top-12    { padding-top:    var(--space-12); }
  .nm\3A padding-top-24    { padding-top:    var(--space-24); }
  .nm\3A padding-top-36    { padding-top:    var(--space-36); }
  .nm\3A padding-top-64    { padding-top:    var(--space-64); }
  .nm\3A padding-top-0     { padding-top:    0; }

  .nm\3A padding-bottom    { padding-bottom: var(--space-v); }
  .nm\3A padding-bottom-12 { padding-bottom: var(--space-12); }
  .nm\3A padding-bottom-24 { padding-bottom: var(--space-24); }
  .nm\3A padding-bottom-36 { padding-bottom: var(--space-36); }
  .nm\3A padding-bottom-64 { padding-bottom: var(--space-64); }
  .nm\3A padding-bottom-0  { padding-bottom: 0; }

  * + .nm\3A margin-top-0  { margin-top: 0; }
  * + .nm\3A margin-top-8  { margin-top: var(--space-8); }
  * + .nm\3A margin-top-10 { margin-top: var(--space-10); }
  * + .nm\3A margin-top-12 { margin-top: var(--space-12); }
  * + .nm\3A margin-top-18 { margin-top: var(--space-18); }
  * + .nm\3A margin-top-24 { margin-top: var(--space-24); }
  * + .nm\3A margin-top-36 { margin-top: var(--space-36); }
  * + .nm\3A margin-top-64 { margin-top: var(--space-64); }

  .nm\3A column-gap-l   { grid-column-gap: var(--column-gap-l); }
  .nm\3A row-gap        { grid-row-gap:    var(--row-gap);      }
  .nm\3A row-gap-l      { grid-row-gap:    var(--row-gap-l);    }

  *[class*="nm:grid"] > * { margin: 0; }
}

@media (--large-viewport) {
  .lg\3A padding-h {
    padding-right: var(--space-v);
    padding-left: var(--space-v);
  }
  
  .lg\3A margin-h {
    margin-right: var(--space-v);
    margin-left: var(--space-v);
  }

  .lg\3A padding-v         { padding-top:    var(--space-v); 
                             padding-bottom: var(--space-v); }
  .lg\3A padding-v-12      { padding-top:    var(--space-12);
                             padding-bottom: var(--space-12); }
  .lg\3A padding-v-24      { padding-top:    var(--space-24);
                             padding-bottom: var(--space-24); }
  .lg\3A padding-v-36      { padding-top:    var(--space-36);
                             padding-bottom: var(--space-36); }
  .lg\3A padding-v-64      { padding-top:    var(--space-64);
                             padding-bottom: var(--space-64); }
  .lg\3A padding-v-0       { padding-top:    0;
                             padding-bottom: 0; }

  .lg\3A padding-top       { padding-top:    var(--space-v); }
  .lg\3A padding-top-12    { padding-top:    var(--space-12); }
  .lg\3A padding-top-24    { padding-top:    var(--space-24); }
  .lg\3A padding-top-36    { padding-top:    var(--space-36); }
  .lg\3A padding-top-64    { padding-top:    var(--space-64); }
  .lg\3A padding-top-0     { padding-top:    0; }

  .lg\3A padding-bottom    { padding-bottom: var(--space-v); }
  .lg\3A padding-bottom-12 { padding-bottom: var(--space-12); }
  .lg\3A padding-bottom-24 { padding-bottom: var(--space-24); }
  .lg\3A padding-bottom-36 { padding-bottom: var(--space-36); }
  .lg\3A padding-bottom-64 { padding-bottom: var(--space-64); }
  .lg\3A padding-bottom-0  { padding-bottom: 0; }

  * + .lg\3A margin-top-0  { margin-top: 0; }
  * + .lg\3A margin-top-8  { margin-top: var(--space-8); }
  * + .lg\3A margin-top-10 { margin-top: var(--space-10); }
  * + .lg\3A margin-top-12 { margin-top: var(--space-12); }
  * + .lg\3A margin-top-18 { margin-top: var(--space-18); }
  * + .lg\3A margin-top-24 { margin-top: var(--space-24); }
  * + .lg\3A margin-top-36 { margin-top: var(--space-36); }
  * + .lg\3A margin-top-64 { margin-top: var(--space-64); }

  .lg\3A column-gap-l   { grid-column-gap: var(--column-gap-l); }
  .lg\3A row-gap        { grid-row-gap:    var(--row-gap);      }
  .lg\3A row-gap-l      { grid-row-gap:    var(--row-gap-l);    }

  *[class*="lg:grid"] > * { margin: 0; }
}

@media (--extra-large-viewport) {
  .xl\3A padding-h {
    padding-right: var(--space-v);
    padding-left: var(--space-v);
  }
  
  .xl\3A margin-h {
    margin-right: var(--space-v);
    margin-left: var(--space-v);
  }

  .xl\3A padding-v         { padding-top:    var(--space-v); 
                             padding-bottom: var(--space-v); }
  .xl\3A padding-v-12      { padding-top:    var(--space-12);
                             padding-bottom: var(--space-12); }
  .xl\3A padding-v-24      { padding-top:    var(--space-24);
                             padding-bottom: var(--space-24); }
  .xl\3A padding-v-36      { padding-top:    var(--space-36);
                             padding-bottom: var(--space-36); }
  .xl\3A padding-v-64      { padding-top:    var(--space-64);
                             padding-bottom: var(--space-64); }
  .xl\3A padding-v-0       { padding-top:    0;
                             padding-bottom: 0; }

  .xl\3A padding-top       { padding-top:    var(--space-v); }
  .xl\3A padding-top-12    { padding-top:    var(--space-12); }
  .xl\3A padding-top-24    { padding-top:    var(--space-24); }
  .xl\3A padding-top-36    { padding-top:    var(--space-36); }
  .xl\3A padding-top-64    { padding-top:    var(--space-64); }
  .xl\3A padding-top-0     { padding-top:    0; }

  .xl\3A padding-bottom    { padding-bottom: var(--space-v); }
  .xl\3A padding-bottom-12 { padding-bottom: var(--space-12); }
  .xl\3A padding-bottom-24 { padding-bottom: var(--space-24); }
  .xl\3A padding-bottom-36 { padding-bottom: var(--space-36); }
  .xl\3A padding-bottom-64 { padding-bottom: var(--space-64); }
  .xl\3A padding-bottom-0  { padding-bottom: 0; }

  * + .xl\3A margin-top-0  { margin-top: 0; }
  * + .xl\3A margin-top-8  { margin-top: var(--space-8); }
  * + .xl\3A margin-top-10 { margin-top: var(--space-10); }
  * + .xl\3A margin-top-12 { margin-top: var(--space-12); }
  * + .xl\3A margin-top-18 { margin-top: var(--space-18); }
  * + .xl\3A margin-top-24 { margin-top: var(--space-24); }
  * + .xl\3A margin-top-36 { margin-top: var(--space-36); }
  * + .xl\3A margin-top-64 { margin-top: var(--space-64); }

  .xl\3A column-gap-l   { grid-column-gap: var(--column-gap-l); }
  .xl\3A row-gap        { grid-row-gap:    var(--row-gap);      }
  .xl\3A row-gap-l      { grid-row-gap:    var(--row-gap-l);    }

  *[class*="xl:grid"] > * { margin: 0; }
}
