.meta-top {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  margin: 0;
  border-bottom: var(--hr);
  text-transform: uppercase;
}

.meta-top > * {
  padding: 0;
  margin: 0;
  white-space: nowrap;
}

.meta-content {
  padding: 0.7em 0 0.5em;
  border-bottom: var(--hr);
}

@media (--medium-viewport) {
  .meta-content {
    display: flex;
    flex-wrap: nowrap;
  }

  .meta-content-group {
    flex-grow: 1;
  }
}
