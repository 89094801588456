.top-bar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  overflow: hidden;
  box-sizing: border-box;
  align-items: baseline;
  width: 100%;
  height: var(--top-bar-h);
  padding-top: calc(0.225 * var(--top-bar-h));
  line-height: 1;
  color: var(--c-top-bar);
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.top-bar > * {
  margin-top: 0;
  margin-bottom: 0;
}

.top-bar-logo {
  display: inline-block;
  height: calc(0.6 * var(--top-bar-h) );
  width: calc(3.46 * 0.6 * var(--top-bar-h));
  overflow: hidden;
  text-indent: -999px;
  margin: 0 0 0 var(--top-bar-h);
  background: transparent url(../images/logo-glean.png) no-repeat 50% 50% / contain;
}

.top-bar-logo-link {
  display: block;
}

.top-bar-baseline {
  display: none;
  height: var(--top-bar-h);
  margin-left: 1em;
  font-size: var(--fs-s);
}

.top-bar-menu {
  display: none;
  flex-grow: 99;
  list-style: none outside none;
  text-align: right;
  font-size: var(--fs-s);
}

.top-bar-menu.include-search {
  margin-right: calc(var(--top-bar-h) - 1em);
}

.top-bar-menu li {
  display: inline-block;
  margin-left: 1em;
}

@media (--medium-viewport) {
  .top-bar-baseline,
  .top-bar-menu {
    display: inline-block;
  }
}
