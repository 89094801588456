@media screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 2dppx) {
  body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }
}

html {
  font-family: var(--f-brut);
}

.f-brut { font-family: var(--f-brut); }
.f-life { font-family: var(--f-life); }

h1, h2, h3, h4, h5, h6, strong {
  font-weight: 400;
}

.t-center { text-align: center; }
.t-justify { text-align: justify; }

.uc { text-transform: uppercase; }

.button {
  font-size:      var(--fs-s);
  letter-spacing: var(--ls-s);
}

@media (--large-viewport) {
  .button {
    font-size:      var(--fs-m);
    letter-spacing: var(--ls-m);
  }
}
