.newsletter-subscription {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: space-around;
}

.newsletter-subscription-form,
.newsletter-result {
  width: 500px;
  max-width: calc(100vw - var(--space-12));
}

[data-newsletter-form="success"] .newsletter-message-failure,
[data-newsletter-form="failure"] .newsletter-message-success {
  display: none;
}
