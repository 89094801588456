.category-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none outside none;
  padding: 0;
  margin: 0 calc(-1 * var(--space-8)) 0 0;
}

.category-list-item {
  flex-grow: 1;
  margin: 0 var(--space-8) var(--space-8) 0;
}

.category-list-item:last-child {
  flex-grow: 0;
}

.category-list::after {
  display: block;
  content: ' ';
  flex-basis: 0;
  flex-grow: 9;
}

.category-list-link {
  width: 100%;
  text-align: center;
}
